// Import the directory theme
$templateColour: blue;

@import "_core";

// project specific CSS goes here

////////////////////////////////
//Alerts//
////////////////////////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

.tt-suggestion:hover {
  background-color: #f3f4f6;
}

.referral-search .tt-suggestion {
  @extend .list-group-item;

  text-align: left;
  text-align: center !important;
}

.referral-search .tt-selectable {
  @extend .list-group-item-action;
}

.searchbox-hr {
  width: 95%;
  border: 0.5px solid #dad9d9;
}

.empty-message {
  padding: 5px 10px;
  text-align: center;
  font-weight: 600;
  font-size: 25px;
}

.typeahead,
.tt-query,
.tt-hint {
  width: 80%;
  height: 60px;
  padding: 8px 12px;
  font-size: 24px;
  line-height: 30px;
  border: 2px solid #ccc;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  outline: none;
  text-align: center;
}

/* add margin to text underneath uploadcare upload button fields */
.post-input-text {
  margin-top: -20px;
}

.background-gradient {
  background-image:
    linear-gradient(
      90deg,
      #0f0527,
      #250b51 35%,
      #250b51 75%,
      #19083b
    );
}

.width-fit-content {
  width: fit-content;
}

// fixes flickering issue with modal: https://github.com/twbs/bootstrap/issues/25206#issuecomment-358903753
.list-group-item,
.list-group-item:hover { z-index: auto; }

.checklist-container {
  display: block;
  position: relative;
  padding-left: 32px;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checklist-container input {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 22px;
  width: 22px;
  background-color: #eee;
  border-radius: 15%;
}

/* When the checkbox is checked, add a blue background */
.checklist-container input:checked ~ .checkmark {
  background-color: $gray-500;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

/* Style the checkmark/indicator */
.checklist-container > .checkmark::after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Show the checkmark when checked */
.checklist-container input:checked ~ .checkmark::after {
  display: block;
}

.line-clamp-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.active {
  background-color: transparent;
}

/* Make styling of modals consistent throughout */
.createAreaHighlightModal .active {
  border: 1px solid #a9eca9 !important;
}

.modal-header {
  border-bottom: none;
}

.modal-title {
  font-size: 1.75rem;
  width: 100%;
  text-align: center;
}

.border-top-radius-zero {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.searchbox-typeahead-input {
  border: 1px solid #ced4da;
  border-radius: 0.2rem;
}

.searchbox-col p {
  margin-bottom: 0;
}

.searchbox-col .searchbox-typeahead-suggestion {
  border: none;
}

.searchbox-section-heading {
  font-size: 18px;
  margin-left: 2.5%;
  color: #000;
}

.searchbox-suggestion-text {
  padding-left: 2.5%;
  font-size: 16px;
  font-weight: $font-weight-normal;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.searchbox-suggestion-icon {
  font-size: 18px;
  margin-right: 7.5px;
}

.searchbox-col ::placeholder {
  font-size: $font-size-base;
  color: #6c7686;
  font-weight: $font-weight-light;
}

.typeahead {
  width: 100%;
  text-align: left;
}

.searchbox-col .twitter-typeahead {
  flex-grow: 1;
}

.searchbox-col .tt-hint,
.tt-input {
  width: 100%;
}

.searchbox-col .tt-menu {
  background-color: white;
  box-shadow: $dropdown-box-shadow;
  border-radius: $border-radius-lg;
  width: 150%;
}

* { box-sizing: border-box; }

/* ---- grid ---- */

.grid {
  max-width: 1200px;
  margin: 0 auto;
}

/* clear fix */
.grid::after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */

.grid-sizer {
  width: 24.25%;
}

.grid-item {
  float: left;
  height: 120px;
}

.grid-item img {
  display: block;
}

.grid-item-main {
  width: 49.5%;
  height: 100%;
}

.grid-item-main img {
  height: 100%;
  width: 100%;
}

.grid-item-quarter {
  width: 24.25%;
  margin-bottom: 1%;
}

.grid-item-quarter img {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.grid-random {
  width: 49.5%;
}

.grid-random img {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

.gutter-sizer { width: 1%; }

.avatar-traveller {
  @extend .avatar;

  padding: 1px !important;
  width: 2.325rem;
  height: 2.325rem;
}

.avatar-anonymous {
  background-image: url("../images/avatar/background.png");
  background-size: cover;
}

.avatar-anonymous:hover {
  text-decoration: none !important;
}

/* ---- rounded buttons ---- */
.btn-rounded {
  width: 180px;
  border-radius: 30px;
}

@for $i from 2 through 4 {
  .text-truncate-#{$i} {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: #{$i};
    -webkit-box-orient: vertical;
    white-space: normal;
  }
}

/* ---- placeholder component styles ---- */
.shimmer {
  background: #f6f7f8;
  background-image:
    linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 60%,
      #f6f7f8 85%,
      #f6f7f8 100%
    );
  background-repeat: no-repeat;
  display: inline-block;
  position: relative;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  -webkit-animation-timing-function: linear;
}

/*
  The shimmer effect needs to move from outside the LHS of the cards, sweep
  across the face of the card, and then move out the RHS.
*/
@keyframes shimmer {
  0% {
    background-position: -350px 0;
  }

  100% {
    background-position: 350px 0;
  }
}

@-webkit-keyframes shimmer {
  0% {
    background-position: -350px 0;
  }

  100% {
    background-position: 350px 0;
  }
}

.btn-link {
  vertical-align: inherit !important;
}
