/*
* ===================================================
*     Typography
* ===================================================
*/

.subtitle {
  margin-bottom: 0;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: $gray-600;
  font-weight: bold;
}

.label-heading {
  color: $gray-600;
  font-size: $font-size-base * 0.8;

  @include uppercase;

  font-weight: normal;
}

.hero-heading {
  margin-bottom: 2rem;
}

@include media-breakpoint-down(md) {
  h1.hero-heading {
    font-size: $h1-font-size * 0.5;
  }

  h2.hero-heading {
    font-size: $h2-font-size * 0.5;
  }

  h3.hero-heading {
    font-size: $h3-font-size * 0.5;
  }
}

@include media-breakpoint-down(sm) {
  .display-1 {
    font-size: $display1-size-small;
  }

  .display-2 {
    font-size: $display2-size-small;
  }

  .display-3 {
    font-size: $display3-size-small;
  }

  .display-4 {
    font-size: $display4-size-small;
  }
}

.text-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ul,
  ol,
  blockquote {
    margin-bottom: 2rem;
  }

  p,
  ul,
  ol {
    font-size: 1.1rem;
    color: $gray-600;
  }
}

p.lead {
  strong {
    font-weight: bold;
  }
}

.btn {
  letter-spacing: 0.3em;
  text-transform: uppercase;
  font-weight: bold;
}

.input-group .btn {
  padding-top: $input-btn-padding-y;
  padding-bottom: $input-btn-padding-y;
}

.btn-sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm - 0.1rem, $btn-line-height-sm, $btn-border-radius-sm);
}

.dropdown-menu {
  box-shadow: $dropdown-box-shadow;
}

.dropdown-header {
  padding: 0.7rem $dropdown-item-padding-x;
  letter-spacing: 0.2rem;

  @include uppercase;
}

.blockquote {
  padding: $spacer;
  border-left: 2px solid theme-color();
}

.blockquote-icon {
  padding-top: 5rem;
  background-image: str-replace(url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='50px' height='50px' viewBox='0 0 508.044 508.044' style='enable-background:new 0 0 508.044 508.044;' fill='#{$blockquoteiconfill}' xml:space='preserve'%3E%3Cg%3E%3Cg%3E%3Cpath d='M0.108,352.536c0,66.794,54.144,120.938,120.937,120.938c66.794,0,120.938-54.144,120.938-120.938 s-54.144-120.937-120.938-120.937c-13.727,0-26.867,2.393-39.168,6.61C109.093,82.118,230.814-18.543,117.979,64.303 C-7.138,156.17-0.026,348.84,0.114,352.371C0.114,352.426,0.108,352.475,0.108,352.536z'/%3E%3Cpath d='M266.169,352.536c0,66.794,54.144,120.938,120.938,120.938s120.938-54.144,120.938-120.938S453.9,231.599,387.106,231.599 c-13.728,0-26.867,2.393-39.168,6.61C375.154,82.118,496.875-18.543,384.04,64.303C258.923,156.17,266.034,348.84,266.175,352.371 C266.175,352.426,266.169,352.475,266.169,352.536z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A"), "#", "%23");
  background-repeat: no-repeat;
}

.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: $hr-border-color;
  text-align: center;
  height: 1.5rem;

  &::before {
    content: '';
    background: $hr-border-color;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &::after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    font-size: 0.8rem;
    padding: 0 0.5em;
    line-height: 1.5rem;
    color: $gray-500;
    background-color: #fff;
  }
}
