/// The Guide color scheme
///
/// Colours are originally from the Reserve Webapp (with green updated).

$primary: #3dba3b;  // tab green
$secondary: #310b6e;  // tab purple

$body-color: #343a40;
$text-muted: #6c7686;

$white: #fff !default;
$gray-100: #f5f9fb !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c7686 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$blue: #007aff !default;
$indigo: #5603ad !default;
$purple: #510fa8 !default;
$pink: #f074ad !default;
$red: #ff3b30 !default;
$orange: #ff9500 !default;
$yellow: #fc0 !default;
$green: #3dba3b !default;
$teal: #24b7fa !default;
$cyan: #4bd6e5 !default;
$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;
