/*

=====================
STYLE SWITCHER FOR DEMO
=====================

*/

#style-switch-button {
  position: fixed;
  top: 160px;
  right: 0;
  border-radius: 0;
  z-index: 2;
  padding: 10px 20px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  box-shadow: $box-shadow;
}

.style-switch-select {
  height: auto !important;
}

#style-switch {
  width: 300px;
  position: fixed;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  top: 213px;
  right: 0;
  background: #fff;
  z-index: 2000;
  box-shadow: $box-shadow-lg;

  h4 {
    color: $gray-700;
  }
}
