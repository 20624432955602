//
// Color system
//

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$grays: ();
$grays: map-merge(
  (
    "100": $gray-100,
    "200": $gray-200,
    "300": $gray-300,
    "400": $gray-400,
    "500": $gray-500,
    "600": $gray-600,
    "700": $gray-700,
    "800": $gray-800,
    "900": $gray-900
  ),
  $grays
);

$violet:  #7a47c2;
$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
$colors: map-merge(
  (
    "blue":       $blue,
    "indigo":     $indigo,
    "purple":     $purple,
    "pink":       $pink,
    "red":        $red,
    "orange":     $orange,
    "yellow":     $yellow,
    "green":      $green,
    "teal":       $teal,
    "cyan":       $cyan,
    "white":      $white,
    "gray":       $gray-600,
    "gray-dark":  $gray-800
  ),
  $colors
);

@if $templateColour == red and not $primary {
  $primary: #e63946;

  @if not $secondary {
    $secondary: #3c69ea;
  }
}

@else if $templateColour == pink and not $primary {
  $primary:  #ef5285;

  @if not $secondary {
    $secondary: #4e66f8;
  }
}

@else if $templateColour == green and not $primary {
  $primary:  #1cb36e;

  @if not $secondary {
    $secondary: #48a3e0;
  }
}

@else if $templateColour == blue and not $primary {
  $primary:  #4e66f8;

  @if not $secondary {
    $secondary: #e83e8c;
  }
}

@else if $templateColour == sea and not $primary {
  $primary:  #59a4b5;

  @if not $secondary {
    $secondary: #e646a6;
  }
}

@else if $templateColour == violet and not $primary {
  $primary:  #9a6ee2;

  @if not $secondary {
    $secondary: #46bac5;
  }
}

@if not $secondary {
  $secondary: $pink;
}

$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);

$theme-color-interval:      8% !default;

$headerHeight: 125px;
$navbarHeight: 72px;

$primary-100: theme-color-level('primary', -12);
$primary-200: theme-color-level('primary', -11);

$spacer: 1rem !default;
$spacers: ();
$spacers: map-merge(
  (
    6: ($spacer * 6),
    7: ($spacer * 8)
  ),
  $spacers
);

$sizes: () !default;
$sizes: map-merge(
  $sizes,
  (
    '100vh': 100vh,
    '1rem': 1rem,
    '2rem': 2rem,
    '3rem': 3rem,
    '6rem': 6rem
  )
);

$grid-gutter-width:           30px !default;

$line-height-lg:              1.5 !default;
$line-height-sm:              1.5 !default;

$component-active-color:      $white !default;
$component-active-bg:         theme-color("primary") !default;

$body-color:                $gray-900 !default;

$border-width:                1px !default;
$border-color:                $gray-300 !default;

$border-radius:               0.4rem !default;
$border-radius-lg:            0.6rem !default;
$border-radius-sm:            0.2rem !default;

$box-shadow-sm:               0 0 0.25rem rgba($black, 0.075) !default;
$box-shadow:                  0 0 1rem rgba($black, 0.15) !default;
$box-shadow-lg:               0 1rem 3rem rgba($black, 0.175) !default;

$font-family-serif:       'Playfair Display', serif !default;
$font-family-sans-serif:  'Poppins', sans-serif !default;
$font-family-heading:     'Poppins' , sans-serif !default;
$font-family-monospace:   menlo, monaco, consolas, "Liberation Mono", "Courier New", monospace !default;
$font-family-base:        $font-family-sans-serif !default;

$font-size-base:              1rem;
$font-size-xl:                ($font-size-base * 2) !default;
$font-size-lg:                ($font-size-base * 1.25) !default;
$font-size-md:                ($font-size-base * 1.125) !default;
$font-size-sm:                ($font-size-base * 0.875) !default;
$font-size-xs:                ($font-size-base * 0.7) !default;

$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;

$line-height-base:            1.6 !default;

$h1-font-size:                $font-size-base * 2.5 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 1.75 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.25 !default;
$h6-font-size:                $font-size-base !default;

$headings-font-family:   $font-family-heading !default;
$headings-font-weight:   700 !default;
$headings-line-height:   1.3 !default;

$display1-size:               6rem !default;
$display2-size:               5.5rem !default;
$display3-size:               4.5rem !default;
$display4-size:               3.5rem !default;

$display1-weight:     400 !default;
$display2-weight:     400 !default;
$display3-weight:     400 !default;
$display4-weight:     400 !default;

$display1-size-small: $display1-size * 0.6 !default;
$display2-size-small: $display2-size * 0.6 !default;
$display3-size-small: $display3-size * 0.6 !default;
$display4-size-small: $display4-size * 0.6 !default;

$input-focus-border-color:              lighten(theme-color('primary'), 15%) !default;
//$input-focus-box-shadow:                none !default;

$dropdown-item-padding-y:           0.7rem !default;
$dropdown-item-padding-x:           1.5rem !default;
$dropdown-header-color:             $gray-500 !default;
$dropdown-box-shadow:               0 0 1.5rem rgba($black, 0.175) !default;
$dropdown-border-width:             0 !default;
$dropdown-link-hover-color:         theme-color('primary') !default;

$navbar-brand-font-size:            $font-size-base !default;
$navbar-padding-y:                  $spacer !default;
$navbar-padding-y-collapsed:        0.5rem !default;
$navbar-link-size:                  0.9rem;

$navbar-dark-color:                 rgba($white, 0.8) !default;
$navbar-dark-hover-color:           $white !default;
$navbar-dark-active-color:          $white !default;

$navbar-light-color:                rgba($black, 0.5) !default;
$navbar-light-hover-color:          rgba($black, 0.9) !default;
$navbar-light-active-color:         $black !default;

$alert-bg-level:                    -11 !default;
$alert-border-level:                -11 !default;
$alert-color-level:                 5 !default;

$list-group-color:                  null !default;
$list-group-bg:                     $white !default;
$list-group-border-color:           rgba($black, 0.075) !default;

$breadcrumb-margin-bottom:          0.5rem !default;
$breadcrumb-bg:                     transparent !default;
$breadcrumb-divider:                quote("—") !default;

$form-group-margin-bottom:              1.5rem !default;

$btn-padding-y:               0.525rem !default;
$btn-font-size:               0.8rem !default;

$btn-padding-y-lg:            0.75rem !default;
$btn-padding-x-lg:            1rem !default;
$btn-font-size-lg:            1rem !default;

$custom-switch-width:                           3rem !default;
$custom-switch-height:                          1.5rem;
$custom-switch-indicator-border-radius:         0.75rem !default;
$custom-switch-indicator-size:                  1.25rem !default;

$modal-content-border-color:        transparent !default;

$table-cell-padding:          1.25rem !default;
$table-accent-bg:             rgba($black, 0.01) !default;
$table-hover-bg:              rgba($black, 0.025) !default;

$badge-padding-y:                   0.5em !default;
$badge-padding-x:                   0.7em !default;

$blockquoteiconfill: theme-color-level('primary', -8);

$embed-responsive-aspect-ratios: (
  (21 9),
  (16, 10),
  (16 9),
  (4 3),
  (3 4),
  (3 2),
  (2 3),
  (1 1)
) !default;
