/*
* ===================================================
*     Breadcrumb
* ===================================================
*/

.breadcrumb {
  font-size: $font-size-md;
  text-decoration: underline;
  display: inline;
  padding-left: 0 !important;
}

.breadcrumb > i {
  font-size: $font-size-sm;
  vertical-align: middle;
  text-decoration: none !important;
}
