// this is the place for your variable overrides, paste variables from custom/_variables.scss here and set your values

$border-radius: 0.2rem;
$border-radius-lg: 0.4rem;
$border-radius-sm: 0.1rem;

$btn-padding-y: 0.425rem !default;
$btn-font-size: 1rem !default;
$btn-font-weight: 400;

$btn-padding-y-lg: 0.65rem !default;
$btn-padding-x-lg: 2rem !default;
$btn-font-size-lg: 1.2rem !default;

$btn-padding-x: 1.2rem;

// $input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm: 0.75rem !default;
// $input-btn-font-size-sm:      $font-size-sm !default;
// $input-btn-line-height-sm:    $line-height-sm !default;

$navbar-padding-y-collapsed: 1rem !default;

// $breadcrumb-divider:                quote(">");

$overflows: auto, hidden, scroll !default;
