/* stylelint-disable */


.fa {
    position: relative;
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    vertical-align: middle !important;
  }

  .fa-chevron-down {
    font-size: 0.8rem !important;
  }

  .navbar .dropdown-menu {
    transform: translateY(0px);
  }

  .nav-tabs {
    margin: 0 -.75rem;
    color: #9aa0ac;
    user-select: none;

    .nav-link {
      margin-bottom: -1px;
      padding: 1rem 0;
      color: inherit;
      font-weight: 400;
      border: 0;
      border-bottom: 1px solid transparent;
      transition: .3s border-color;

      &:hover:not(.disabled) {
        color: #6e7687;
        border-color: #6e7687;
      }

      &.active {
        color: #467fcf;
        background: transparent;
        border-color: #467fcf;
      }

      &.disabled {
        cursor: default;
        opacity: .4;
        pointer-events: none;
      }
    }

    .nav-item {
      position: relative;
      margin-bottom: 0;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;

      i {
        display: inline-block;
        width: px2rem(14px);
        margin-right: .25rem;
        font-size: px2rem(18px);
        line-height: 1;
        vertical-align: baseline;
      }


    }

    .nav-submenu {
      position: absolute;
      z-index: 10;
      display: none;
      min-width: 10rem;
      background: #fff;
      border: 1px solid  rgba(0, 40, 100, .12);
      border-top: 0;
      border-radius: 0 0 3px 3px;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    }

    .nav-submenu .nav-item {
      display: block;
      margin: 0 !important;
      padding: .5rem 1rem;
      color: #9aa0ac;
      cursor: pointer;
      transition: .3s background;

      &.active {
        color: $link-color;
      }

      &:hover {
        color: #6e7687;
        text-decoration: none;
        background: rgba(0, 0, 0, .024);
      }
    }
  }

  .dropdown {
    display: inline-block;
  }

  .dropdown-menu {
    min-width: 12rem;
    box-shadow: $dropdown-box-shadow;
    margin-top: -10rem;
  }

  .dropdown-item {
    color: #6e7687;
  }

  .dropdown-menu-arrow {
    &::before {
      position: absolute;
      top: -12px;
      left: 12px;
      display: inline-block;
      border-right: 5px solid transparent;
      border-bottom: 5px solid  rgba(0, 40, 100, .12);
      border-bottom-color: rgba(0, 0, 0, 0.2);
      border-left: 5px solid transparent;
      content: '';
    }

    &::after {
      position: absolute;
      top: -12px;
      left: 12px;
      display: inline-block;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
      border-left: 5px solid transparent;
      content: '';
    }

    &.dropdown-menu-right {
      &::before,
      &::after {
        right: 12px;
        left: auto;
      }
    }
  }

  .dropdown-toggle {
    cursor: pointer;
    user-select: none;

    &::after {
      vertical-align: 0.155em;
    }

    &:empty::after {
      margin-left: 0;
    }
  }

  .dropdown-icon {
    display: inline-block;
    width: 1em;
    margin-right: .5rem;
    margin-left: -.5rem;
    color: #9aa0ac;
    text-align: center;
    vertical-align: -1px;
  }

  /* Mobile-friendly styles */
  @media (max-width: 768px) {
    .nav {
      padding: 10px;
    }

    .nav-tabs .nav-link {
      padding: 0rem 0;
      margin: 0rem;
    }

    .nav-tabs .nav-item {
      padding-left: 5px;
      padding-right: 5px;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    .nav-tabs .nav-submenu {
      min-width: 8rem;
    }

    .dropdown-menu {
      min-width: 10rem;
      margin-top: 0 !important;
      padding-top: 0 !important;
      background: rgba(42,40,61);
    }

    .dropdown-item {
      color: #9aa0ac;
    }
    .dropdown-item:focus, .dropdown-item:hover {
      background-color: rgba(42, 40, 61);
      color: #3dba3b;
      text-decoration: none;
      }

    .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
      background-color: rgba(42, 40, 61);
      color:  #3dba3b;
      }
  }