// this is the place for your components / CSS
@use "user-variables";

.btn {
  letter-spacing: normal;
  text-transform: inherit;
  font-weight: 400;
}

.btn-sm {
  letter-spacing: normal;
  text-transform: inherit;
  font-weight: 600;
}

.subtitle {
  letter-spacing: 0.1em;
}

.navbar .nav-link {
  font-size: 1rem;
  font-weight: 400;
}

.caps {
  text-transform: uppercase;
}

.rainbow-bg {
  background:
    linear-gradient(to left, #0003, #0003),
    linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
}

.listings-map-icon {
  position: relative;
  text-align: center;
  font-weight: bold;
  width: 26px;
  height: 26px;
  border-radius: 5px;
  margin-top: -34px; /* Shift by arrow top+height */
  margin-left: -13px; /* Shift by half the marker width */
}

.listings-map-icon.active {
  background: #fff;
}

.listings-map-icon div.icon {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #3dba3b;
  width: 24px; /* Same as marker width */
  height: 24px; /* Same as marker height */
  line-height: 21px;
  font-size: 12px;
  border-radius: 4px;
  //   border: 1px solid green;
  margin-left: 1px;
  margin-top: 1px;
}

.listings-map-icon div.icon.active {
  background: #3da33b;
}

.listings-map-icon div.arrow {
  position: relative;
  border-style: solid;
  border-color: #3dba3b transparent transparent transparent;
  border-width: 10px 6px 0 6px; /* Arrow w/h is defined by the borders */
  left: 7px; /* (Marker width - arrow width)/2 */
  width: 0;
  height: 0;
}

.listings-map-icon div.arrow.active {
  border-color: #3da33b transparent transparent transparent;
}

.active {
  color: #343a40;
  background-color: rgba(0, 0, 0, 0.025);
}

/* Used to bring titles above a swiper to the surface, so they can be selected/clicked */
.swiper-title {
  position: relative;
  z-index: 2;
  width: max-content;
}

.bg-mint {
  background-color: $mint-green;
}

.bg-orange {
  background-color: $orange;
}

.bg-primary {
  background-color: $primary;
}

.bg-secondary {
  background-color: $secondary;
}
