/*
* ===================================================
*    Custom Nav Pills
* ===================================================
*/
.nav-pills-custom {
  flex-wrap: wrap;

  .nav-link {
    margin: 10px;
    color: theme-color('primary');
    border: 1px solid theme-color('primary');
    border-radius: 50px;

    &.active,
    &:hover,
    &:focus {
      color: #fff;
      background: theme-color('primary');
    }

    &:first-of-type {
      margin-left: 0;
    }
  }
}
