/*
 * These font subsets were generated using glyphhanger on the understanding that
 * the majority of our customers use english to describe their sites. When they
 * use a native language it is a langauge that we don't have the glyphs for
 * anyway (e.g. Mandarin) and so the fallback system font is used anyway.
 * These were generated with:
 *   glyphhanger --LATIN --subset='*.woff2' --css
 */
@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-Regular-subset.woff2") format("woff2");
  unicode-range: U+0-FF, U+131, U+152, U+153, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-Bold-subset.woff2") format("woff2");
  unicode-range: U+0-FF, U+131, U+152, U+153, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-SemiBold-subset.woff2") format("woff2");
  unicode-range: U+0-FF, U+131, U+152, U+153, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url("../fonts/SourceSansPro-Light-subset.woff2") format("woff2");
  unicode-range: U+0-FF, U+131, U+152, U+153, U+2BB, U+2BC, U+2C6, U+2DA, U+2DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

$font-family-sans-serif: "Source Sans Pro", -apple-system, blinkmacsystemfont,
  "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-heading: "Source Sans Pro", -apple-system, blinkmacsystemfont,
  "Segoe UI", roboto, "Helvetica Neue", arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;

$headings-font-family: $font-family-heading;
$headings-font-weight: 400;
$headings-line-height: 1.3;
