/*
* ===================================================
*    Icons + Badges
* ===================================================
*/
.badge-transparent {
  color: $gray-900;
  background: rgba($gray-100, 0.9);
}

.icon-circle {
  width: 20px;
  height: 20px;
  text-align: center;
  color: $white;
  border-radius: 50%;
  font-size: 0.5rem;
  line-height: 20px;

  i {
    vertical-align: middle;
  }
}

.icon-rounded {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  text-align: center;
  line-height: 4rem;

  svg,
  i {
    vertical-align: middle;
  }

  &.icon-rounded-sm {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
  }

  &.icon-rounded-lg {
    width: 5rem;
    height: 5rem;
    line-height: 5rem;
  }

  &.icon-rounded-xl {
    width: 7rem;
    height: 7rem;
    line-height: 7rem;
  }
}

.svg-icon {
  width: 18px;
  height: 18px;
  vertical-align: text-bottom;
  stroke: currentColor;
  stroke-width: 3;

  --layer1: currentColor;
  --layer2: currentColor;

  &.svg-icon-light {
    stroke-width: 2;
  }

  &.svg-icon-heavy {
    stroke-width: 4;
  }

  &.svg-icon-md {
    width: 30px;
    height: 30px;
  }

  &.svg-icon-lg {
    width: 36px;
    height: 36px;
  }
}
