/**
 * Directory – Directory & Listing Bootstrap 4 Theme v. 1.4.2
 * Homepage: https://themes.getbootstrap.com/product/directory-directory-listing-bootstrap-4-theme/
 * Copyright 2020, Bootstrapious - https://bootstrapious.com
 */

// functions.scss - Bootstrap functions
@import "bootstrap/scss/functions";

// your variables + overrides of Bootstrap variables
@import "user/colours";
@import "user/user-variables";
@import "user/fonts";

// custom variables + overrides of Bootstrap variables for this theme
@import "custom/variables";

// bootstrap main
@import "bootstrap/scss/bootstrap";

// 3rd party plugin variables
@import "custom/variables-3rd-party.scss";

// theme components
@import "custom/theme";

// Font awesome
// We do this inline here so that we only serve the icons that we use rather
// than the full suite of icons.
$fa-font-path: "../node_modules/@fortawesome/fontawesome-pro/svgs/";
$fa-brands-font-path: "../node_modules/@fortawesome/fontawesome-pro/svgs/brands";

@import '@fortawesome/fontawesome-pro/scss/variables';
@import '@fortawesome/fontawesome-pro/scss/mixins';
@import '@fortawesome/fontawesome-pro/scss/core';
@import '@fortawesome/fontawesome-pro/scss/fixed-width';
@import '@fortawesome/fontawesome-pro/scss/list';
@import '@fortawesome/fontawesome-pro/scss/larger';
@import '@fortawesome/fontawesome-pro/scss/stacked';

// add your fontawesome icons here
$used-icons: (
  bars,
  arrow-right,
  long-arrow-alt-right,
  user,
  external-link-alt,
  check,
  envelope-open,
  arrow-left,
  home,
  user-alt,
  paper-plane,
  times,
  angle-right,
  angle-down,
  bed,
  utensils,
  beer,
  language,
  phone,
  map-marker-alt,
  compass,
  dollar-sign,
  wine-glass,
  coffee,
  cocktail,
  tasks,
  list-ul,
  plus,
  credit-card,
  calendar,
  chevron-left,
  chevron-right,
  chevron-down,
  star,
  trash,
  edit,
  copy,
  envelope,
  user-friends,
  utensils,
  heart,
  flag,
  map-signs,
  share-square,
  hiking,
  users,
  clock,
  search,
  chevron-circle-right,
  chevron-circle-left,
  circle,
  glass-martini-alt,
  coffee,
  camera,
  globe-americas,
  globe-stand,
  badge-check,
  link,
  bookmark,
  percent,
  eye,
  award,
  info-circle,
  id-card,
  id-card-alt,
  tags,
  sitemap,
  sliders-v,
  thumbs-up,
  lock-open-alt,
  redo,
  tag,
  random,
  check-circle,
  hashtag,
  user-plus,
  concierge-bell,
  ticket-alt,
  sign-out-alt,
  browser,
  quote-left,
  globe-americas,
  sign-in,
  user-circle,
  ellipsis-h,
  arrow-up,
  arrow-to-bottom,
  passport,
  shopping-cart,
  clipboard-list-check,
  suitcase,
  pen,
  wrench,
  question-circle,
);

// create class for each version of icon (solid, regular and light)
@each $name in $used-icons {
  .#{$fa-css-prefix}-#{$name}:before {
    font-icon: url('#{$fa-font-path}/solid/#{$name}.svg');
  }
  .far-#{$name}:before {
    font-icon: url('#{$fa-font-path}/regular/#{$name}.svg');
  }
  .fal-#{$name}:before {
    font-icon: url('#{$fa-font-path}/light/#{$name}.svg');
  }
}

// add your fontawesome brand icons here
$used-brands: (
  facebook, instagram, twitter, facebook-messenger, whatsapp-square,
  spotify, tiktok, youtube, whatsapp
);

@each $name in $used-brands {
  .#{$fa-css-prefix}-#{$name}:before {
    font-icon: url('#{$fa-brands-font-path}/#{$name}.svg');
  }
}

.fa,
.fab,
.fas,
.far {
  vertical-align: middle;
}

// your components
@import "user/user";

// dropdown navbar
@import "custom/dropdown-navbar";
